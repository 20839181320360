import React, { useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components'
import { onSnapshot, collection, query, where } from 'firebase/firestore'
import { db } from '../system/firebase/index'
import { connect } from 'react-redux';


const Salutation = ({ vehicle }) => {
    const [client, setClient] = useState()
    useEffect(() => {
        const q = query(collection(db, 'users'), where('uid', '==', vehicle.uid ))
        onSnapshot(q, (snapshot) => {
            const output = []
            snapshot.docs.map((doc) => {
                output.push(doc.data())
            })
            setClient(output[0])
        })
    }, [vehicle])
    const name = client && client.name ? client.name.salutation + ' ' + client.name.first.charAt(0) + ' ' + client.name.last : null
    return (
        name && vehicle ?
            <Box mb='lg' ml='sm'>
                <Box>
                    <Text variant='h1' my='15px'>
                        {name}
                    </Text>
                </Box>
                <Flex color='white' alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']} flexDirection={['column', 'column', 'column', 'column', 'column', 'row']}>
                    <Box><Text as='p' letterSpacing='3px' variant='largeCopy'> {vehicle.title}</Text></Box>
                    <Box display={['none', 'none', 'none', 'none', 'none', 'block']} mx='sm'>|</Box>
                    <Box><Text as='p' letterSpacing='3px' variant='largeCopy' fontWeight='300'>Chassis No: {vehicle.characteristics.chassisNo}</Text></Box>
                </Flex>
            </Box>: null
    );
};

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
    };
}
export default connect(mapStateToProps)(Salutation)
