import React, { useEffect } from 'react'
import Layout from '../../../templates/Main'
import Timeline from '../../../modules/Timeline'
import Salutation from '../../../modules/Salutation'
import { Box } from 'rebass/styled-components'
import Swiper from '../../../modules/Swiper'
import { setVehicle } from '../../../models/Vehicles'
import { connect } from 'react-redux'
import UploadModal from '../../../modules/UploadModal'
import Modal from '../../../modules/Modal'


const RestorersDashboardPage = ({ params, dispatch }) => {
    useEffect(() => {
        setVehicle(dispatch, params.id)
    }, [params.id])

    return (
        <Layout id={params.id}>
            <Salutation />
            <Box variant='container'>
                <Timeline />
                <Swiper type='invoices' />
                <Swiper type='quotes'/>
                <UploadModal />
                <Modal />
            </Box>
        </Layout>
    )
}

export default connect()(RestorersDashboardPage);

